import React, { Component } from 'react';
import Frame from "../../components/frame";
import Joinnow from "./joinnow";
import Form from "./form";
import Pools from "./pools";
import {withRouter,Link} from "react-router-dom";
import Cashlab from "../../contracts/Cashlab.json";
import getWeb3 from "../../getWeb3";

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        storageValue: 0,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        poolDeposit:[0,0,0],
        poolIncome:[0,0,0],
        TotalBonus:0,
        totalWithdrawn:0,
        totalDeposits:0,
        referral_count:[0,0,0,0,0,0],
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
      }      
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = Cashlab.networks[networkId];
          const instance = new web3.eth.Contract(
            Cashlab.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);

      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      let userInfo = await contract.methods.users(accounts).call();
      userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
      userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
      let poolDeposit = [];
      for(let i=0;i<3;i++){
        let tempVal = await contract.methods.pool_deposits(i).call();
        tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
        poolDeposit.push(tempVal);
      }

      let poolIncome = [];
      for(let i=0;i<3;i++){
        let tempVal = await contract.methods.user_bonus_pool(accounts,i).call();
        tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
        poolIncome.push(tempVal);
      }

      let TotalBonus = await contract.methods.TotalBonus(accounts).call();
      TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
      let totalWithdrawn = await contract.methods.totalWithdrawn().call();
      totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
      let totalDeposits = await contract.methods.totalInvested().call();
      totalDeposits = this.state.web3.utils.fromWei(totalDeposits, 'ether');

      let referral_count = [];
      for(let i=0;i<6;i++){
        let tempVal = await contract.methods.referral_count(accounts,i).call();
        referral_count.push(tempVal);
      }      

      this.setState({ 
        userInfo,
        poolDeposit,
        poolIncome,
        TotalBonus,
        totalWithdrawn,
        totalDeposits,
        referral_count,
        networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
      });

     
    }

    render() {
      return (
        <Frame withHeader={true} withFooter={true} showEnter={true}>

          <Joinnow props={this.props} state={this.state} fetchData={this.fetchData}></Joinnow>
          
          {/* Banner */}
          <div className="banner_section middle_text">
          <div id="particles-js"></div>
          <div className="container">
            <div className="banner_text">
              <div className="text-center pb_30">
                <img className="banner_logo" src="img/lwhite_ogo_icon.png" alt="" />
                <h1>Let us introduce a true and transparent contribution algorithm with you.</h1>
                <p>There are several companies are trying to resolve the one major issues in world of donation.</p>
              </div>
            
            </div>
          </div>  
        </div>
           {/* Banner */}


          <section className="pt_60 pb_60" id="about">
            <div className="container">
           

            <div className="row">
              <div className="col-md-7 col-sm-7">
                <p className="text_line_height">
                  Trust and the transparency are the biggest problems when you choose to donate. 
                </p>
                <p className="text_line_height">
                  In this a lot of donors gives donation every year by their choice for different good causes to different NGO activities and in so many others organization in the process of help to need one. Due to middle man and due so many other factors the help never reach the person which doesn’t achieve the desired goal. 
                </p>
                <p className="text_line_height">
                  Now with the use of blockchain technology some good companies are trying to solve this issue in war level. As we know the world is facing the biggest pandemic – Coronavirus. And this is not going away from our life. 
                </p>
                <p className="text_line_height">
                  To achieve the goal, every capable individual is trying their best to offer the best possible solutions. So, is Cashlab Finance. Promoting the concept of decentralized donation, Cashlab Finance is eradicating the problem areas and rebuilding a secure and advanced ecosystem. 
                </p>
              </div>
              <div className="col-md-5 col-sm-5">
                <img className="img-fluid" src="img/image1.png" alt="" />
              </div>
            </div>
          
            </div>
          </section>

          <section className="pb_60">
            <div className="container text-center">
              <div className="box_bg">
                <div className="sm_heading text-center">
                  <h2>BENEFITS OF DECENTRALIZED DONATION</h2>
                 </div>
                 <br />
                <p>When a donor donates to a charity, they have the trust that the NGO will ensure in spending the money well. However, many Non-Profit Organizations are not up-front about disclosing the fund distribution terms and specifics, resulting into falsifying records. </p>
                <p>With decentralized donation, the trust and transparency towards funds are kept intact. Donors will have knowledge about their donations being used wisely. No data is tampered, neither record is falsified. Decentralized donation eradicates every factor which challenges a safe and transparent ecosystem. </p>
              </div>
             

            </div>
          </section>

          <section className="pb_60">
            <div className="container">
            
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <img className="img-fluid" src="img/image2.png" alt="" />
              </div>
              <div className="col-md-6 col-sm-6 middle_text">
                <div className="all_heading">
                  <h2>What is Binance Smart Contract?</h2>
                </div>
                <p className="text_line_height">When it comes to running smart contract-based applications Binance Smart Chain is the only blockchain network available. This blockchain network runs side by side along with the Binance native chain delivering the best of the two (high transaction capacity &amp; BSC smart contract functionality) to the users.</p>
                
                </div>
              
            </div>
          
            </div>
          </section>

          <Pools></Pools>

          <section className="">
            <div className="container">
              <div className="all_heading text-center">
                <h2>All your  <span>queries answered!</span></h2>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="accordion_border">
                    <div className="accordion_head">
                      <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                      What can we do on Binance Chain?
                    </div>
                    <div className="accordion_body">
                      <p>In terms of sending or receiving decentralized gifts and coins, Binance Chain does the job well. Apart from sending or receiving, you can also mint new tokens, create new tokens, follow the terms & conditions mentioned between two different token pairs, and buy or sell digital currencies using the trade pairs earlier created on the Binance Chain exchange or platform. </p>
                    </div>
                  </div>
                  <div className="accordion_border">
                    <div className="accordion_head">
                      <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                      How to configure a wallet on BSC?
                    </div>
                    <div className="accordion_body">
                      <p>The wallet creation process on BSC is easy to understand. The most important thing to remember here is to first set up on Trust wallet which can is best done using a browser on the computer or even use the mobile app as well. On completion of the configuration process as per the terms, the wallet will be activated to make deposits and withdrawals. </p>
                    </div>
                  </div>
                  <div className="accordion_border">
                    <div className="accordion_head">
                      <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                      How do I issue and exchange coins?
                    </div>
                    <div className="accordion_body">
                      <p>Issuing and exchanging coins is very simple and can be related to the exchange of other cryptocurrencies as well. The only difference is that a BNB coin can be used for trading and making payments, both at the same time. The coin running on the Ethereum blockchain comes engraved with a BNB symbol on it and is issued by the Binance exchange. The best part is, these coins can also be exchanged with other decentralized currencies like Bitcoin and others but will have a limiting cap of 200 million tokens. </p>
                    </div>
                  </div>
                
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="accordion_border">
                    <div className="accordion_head">
                      <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                      How does Binance Smart Chain function?
                    </div>
                    <div className="accordion_body">
                      <p> Binance Smart Chain is commonly known to function as per the Proof-of-Stake (POS) consensus algorithm Binance Smart Chain. However, Proof-of-Staked Authority or PoSA consensus also comes into use as well. The only difference here is the network participants will be allowed to serve and stake only as proper validators. Once a block gets validated the transaction fees will be released automatically.</p>
                    </div>
                  </div>
                  <div className="accordion_border">
                    <div className="accordion_head">
                      <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                      How to receive BNB on the Binance smart chain?
                    </div>
                    <div className="accordion_body">
                      <p>BNB on the Binance smart chain can be received only as BNB or BNB directly getting transferred from a Trust wallet. Using the important details BNB can be received from Binance smart chain. However, using a Trust wallet simplifies the process and helps you receive BNB using the address of a Trust wallet.  </p>
                    </div>
                  </div>
                  <div className="accordion_border">
                    <div className="accordion_head">
                      <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                      How is Metamask connected to Binance Smart Chain?
                    </div>
                    <div className="accordion_body">
                      <p>Metamask is connected to Binance Smart Chain to make the entire process more secure and utilizable. Metamask is always created on Ethereum-based websites. If you are using a mobile device then things get much easier as the connection can be made using mobile apps available on the app stores (iOS & Google Play). Now the thing that remains is, to create a wallet that completes the entire process of sending and receiving funds. </p>
                    </div>
                  </div>
                 
               
                </div>
              </div>

            
            </div>
          </section>

         {/* Features */}
        <section className="form_bg" id="Features">
          <div className="container">
            <div className="box_shadow">
            <div className="row">
              <div className="col-md-5 col-sm-5">
                <img className="img-fluid" src="img/friends.jpg" alt="" />
              </div>
              <div className="col-md-7 col-sm-7 middle_text">
                <div className="box_padding">
                  <div className="ext_heading">
                    <h2>What is Cashlab.finance?</h2>
                  </div>
                  <p className="mb-0">Cashlab Finance recognizes the desires of donors for a transparent and accountable decentralized donation platform. Eradicating the problem areas, Cashlab Finance focuses on opportunities for a blockchain-based platform to gain trust ensuring transparency and security to the donors.  </p>
                </div>
              </div>
            </div>
        
            </div>
            <div className="box_shadow">
            <div className="row">
              
              <div className="col-md-7 col-sm-7 middle_text">
                <div className="box_padding">
                  <div className="ext_heading">
                    <h2>What is BNB coin?</h2>
                  </div>
                  <p className="mb-0">
                  Binance Coin is the cryptocurrency issued by the Binance exchange and trades with the BNB symbol.
                  BNB was initially based on the Ethereum network but is now the native currency of Binance's own blockchain, the Binance chain.
                  Every quarter, Binance uses one-fifth of its profits to repurchase and permanently destroy, or "burn," Binance coins held in its treasury.
                  Binance was created as a utility token for discounted trading fees in 2017, but its uses have expanded to numerous applications, including payments for transaction fees (on the Binance Chain), travel bookings, entertainment, online services, and financial services.
                  At the time of writing, Binance had a market cap of $88,276,993,118 and ranks behind only Bitcoin and Ethereum in terms of size.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-5">
                <img className="img-fluid" src="img/bnb-coin.jpg" alt="" />
              </div>
            </div>
        
            </div>
           
          </div>
        </section>
         {/* Features */}


       

        </Frame>
      );
    }
  }

  export default withRouter(Home);
  //export default Home;