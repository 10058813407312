import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      //console.log('header',this.props);
      return (
        <header>
          <div className="container">
            <div className="menu_section">
              <div className="row">
                <div className="col-md-3 col-lg-3 col-sm-3 col-6 middle_text">
                  <a href="/">
                    <img className="logo_text" src="img/white_logo.png" alt="" />
                  </a>
                </div>
                <div className="col-md-9 col-lg-9 col-6 col-sm-9">
                  <div className="stellarnav wow fadeInDown">
                    <ul className="banner_login">
                      <li><a className="slow_link" href="/">Home</a></li>
                      <li><a className="slow_link" href="/#about">About</a></li>
                      <li><a className="slow_link" href="/#faq">Faq</a></li>
                      <li><a className="slow_link" href="/#Features">Features</a></li>
                      <li><a className="slow_link" href="/guide">Guide</a></li>
                    </ul>
                    {this.props.showEnter && <span id="openNav">Enter</span> }                    
                    
                    <div className="menu_overlay close-menu"></div>
                  </div>  
                </div>
              </div>
            </div>
          
          </div>
      </header>
      );
    }
  }

  export default Header;