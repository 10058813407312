import React, { Component } from 'react';

class Pools extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
        console.log('from Pools');
      return (

      <div>

      <section className="pb_60">
        <div className="container">
          <div className="pt_40 pb_40">
            <div className="sm_heading text-center">
              <h2>Expected friends and expected gift from them</h2>
              <p>As by smart contract documents we can add maximum 4 donors</p>
            </div>
          </div>

          <div className="row">
           
            <div className="col-md-12 col-md-12">
              <div className="table-responsive box_bg">
                <table className="table">
                  <thead>
                  <tr>
                    <th style={{width: "25%"}}>Serial</th>
                    <th style={{width: "25%"}}>Donors</th>
                    <th style={{width: "25%"}}>Donation</th>
                    <th style={{width: "25%"}}>Expected Donation</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th>1</th>
                    <td>4</td>
                    <td>0.025</td>
                    <td>0.10</td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>16</td>
                    <td>0.010</td>
                    <td>0.160</td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td>64</td>
                    <td>0.008</td>
                    <td>0.512</td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td>256</td>
                    <td>0.004</td>
                    <td>1.024</td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td>1024</td>
                    <td>0.008</td>
                    <td>8.192</td>
                  </tr>
                  <tr>
                    <th>6</th>
                    <td>4096</td>
                    <td>0.010</td>
                    <td>40.96</td>
                  </tr>
                  </tbody>
                  <thead className="thead-light">
                  <tr>
                    <th colspan="3">Received Donation</th>
                    <th>Total = 50.98 BNB</th>
                  </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb_60">
        <div className="container">
          <div className="pb_40">
            <div className="sm_heading text-center">
              <h3>How does this entire arrangements has been associated</h3>
              <p>Minimum donation amount is 0.1 BNB</p>
              <p>Divide in 6 upline</p>
              <p><strong>Upline 1. 25% , Upline 2. 10% , Upline 3. 8% , Upline 4. 4% , Upline 5. 8% , Upline 6. 10%</strong></p>
              <h2>There are 3 pools</h2>
            </div>
          </div>
         

          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="pool_box">
                <h2>Pool 1</h2>
                <hr />
                <h4>20%.</h4>
                <h4>Condition</h4>
                <h4>Do 4 direct partner in 4 day's</h4>
                <h4>Remain in pool till</h4>
                <h4>received 1 BNB from</h4>
                <h4>any resources</h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="pool_box">
                <h2>Pool 2</h2>
                <hr />
                <h4>10%.</h4>
                <h4>Achieve 4/16</h4>
                <h4>matrix in 30 day's</h4>
                <h4>Remain in pool</h4>
                <h4>till 5 BNB from</h4>
                <h4>any resources</h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="pool_box">
                <h2>Pool 3</h2>
                <hr />
                <h4>5%.</h4>
                <h4>Achieve 4/16/64 in 100 day'</h4>
                <h4>And remain in</h4>
                <h4>pool till 25 BNB</h4>
                <h4>from any</h4>                
                <h4>resources </h4>
              </div>
            </div>
          
          </div>

        </div>
      </section>
      
      </div>

        );
    }
  }

  export default Pools;